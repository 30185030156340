import React from 'react';
import { Script, ScriptStrategy } from 'gatsby';

export const AnalyticsLinkedin = () => {
  return (
    <>
      <Script strategy={ScriptStrategy.postHydrate}>
        {`
          _linkedin_partner_id = "2969202";
          window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
          window._linkedin_data_partner_ids.push(_linkedin_partner_id);
        `}
      </Script>
      <Script strategy={ScriptStrategy.postHydrate}>
        {`
          (function(){var s = document.getElementsByTagName("script")[0];
          var b = document.createElement("script");
          b.type = "text/javascript";b.async = true;
          b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
          s.parentNode.insertBefore(b, s);})();
        `}
      </Script>
    </>
  );
};
