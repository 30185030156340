import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../index';

const domain = (state: RootState) => state.Modals;

export const isModalOpen = createSelector(domain, (state) => state.isOpen);

export const getModalType = createSelector(domain, (state) => state.modalType);

export const getModalPayload = createSelector(domain, (state) => state.payload);

export const getModalProps = createSelector(domain, (state) => state.modalProps);

export const getModalContext = createSelector(domain, (state) => state.context);
