exports.components = {
  "component---src-features-blog-page-tsx": () => import("./../../../src/features/blog/page.tsx" /* webpackChunkName: "component---src-features-blog-page-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-uinno-tsx": () => import("./../../../src/pages/about-uinno.tsx" /* webpackChunkName: "component---src-pages-about-uinno-tsx" */),
  "component---src-pages-blog-index-tsx": () => import("./../../../src/pages/blog/index.tsx" /* webpackChunkName: "component---src-pages-blog-index-tsx" */),
  "component---src-pages-cases-ai-age-validation-case-study-index-tsx": () => import("./../../../src/pages/cases/ai-age-validation-case-study/index.tsx" /* webpackChunkName: "component---src-pages-cases-ai-age-validation-case-study-index-tsx" */),
  "component---src-pages-cases-ai-chatbot-case-study-index-tsx": () => import("./../../../src/pages/cases/ai-chatbot-case-study/index.tsx" /* webpackChunkName: "component---src-pages-cases-ai-chatbot-case-study-index-tsx" */),
  "component---src-pages-cases-ai-healthcare-case-study-index-tsx": () => import("./../../../src/pages/cases/ai-healthcare-case-study/index.tsx" /* webpackChunkName: "component---src-pages-cases-ai-healthcare-case-study-index-tsx" */),
  "component---src-pages-cases-betting-case-study-index-tsx": () => import("./../../../src/pages/cases/betting-case-study/index.tsx" /* webpackChunkName: "component---src-pages-cases-betting-case-study-index-tsx" */),
  "component---src-pages-cases-blockchain-integration-web-3-gaming-case-study-index-tsx": () => import("./../../../src/pages/cases/blockchain-integration-web3-gaming-case-study/index.tsx" /* webpackChunkName: "component---src-pages-cases-blockchain-integration-web-3-gaming-case-study-index-tsx" */),
  "component---src-pages-cases-cad-development-case-study-index-tsx": () => import("./../../../src/pages/cases/cad-development-case-study/index.tsx" /* webpackChunkName: "component---src-pages-cases-cad-development-case-study-index-tsx" */),
  "component---src-pages-cases-cybersecurity-mvp-case-study-index-tsx": () => import("./../../../src/pages/cases/cybersecurity-mvp-case-study/index.tsx" /* webpackChunkName: "component---src-pages-cases-cybersecurity-mvp-case-study-index-tsx" */),
  "component---src-pages-cases-e-dopomoga-case-study-index-tsx": () => import("./../../../src/pages/cases/e-dopomoga-case-study/index.tsx" /* webpackChunkName: "component---src-pages-cases-e-dopomoga-case-study-index-tsx" */),
  "component---src-pages-cases-ecotech-case-study-index-tsx": () => import("./../../../src/pages/cases/ecotech-case-study/index.tsx" /* webpackChunkName: "component---src-pages-cases-ecotech-case-study-index-tsx" */),
  "component---src-pages-cases-edtech-case-study-index-tsx": () => import("./../../../src/pages/cases/edtech-case-study/index.tsx" /* webpackChunkName: "component---src-pages-cases-edtech-case-study-index-tsx" */),
  "component---src-pages-cases-hrtech-case-study-index-tsx": () => import("./../../../src/pages/cases/hrtech-case-study/index.tsx" /* webpackChunkName: "component---src-pages-cases-hrtech-case-study-index-tsx" */),
  "component---src-pages-cases-index-tsx": () => import("./../../../src/pages/cases/index.tsx" /* webpackChunkName: "component---src-pages-cases-index-tsx" */),
  "component---src-pages-cases-investtech-case-study-index-tsx": () => import("./../../../src/pages/cases/investtech-case-study/index.tsx" /* webpackChunkName: "component---src-pages-cases-investtech-case-study-index-tsx" */),
  "component---src-pages-cases-machine-learning-case-study-index-tsx": () => import("./../../../src/pages/cases/machine-learning-case-study/index.tsx" /* webpackChunkName: "component---src-pages-cases-machine-learning-case-study-index-tsx" */),
  "component---src-pages-cases-ml-basketball-case-study-index-tsx": () => import("./../../../src/pages/cases/ml-basketball-case-study/index.tsx" /* webpackChunkName: "component---src-pages-cases-ml-basketball-case-study-index-tsx" */),
  "component---src-pages-cases-punt-club-case-study-index-tsx": () => import("./../../../src/pages/cases/punt-club-case-study/index.tsx" /* webpackChunkName: "component---src-pages-cases-punt-club-case-study-index-tsx" */),
  "component---src-pages-cases-tech-consulting-case-study-index-tsx": () => import("./../../../src/pages/cases/tech-consulting-case-study/index.tsx" /* webpackChunkName: "component---src-pages-cases-tech-consulting-case-study-index-tsx" */),
  "component---src-pages-contact-uinno-tsx": () => import("./../../../src/pages/contact-uinno.tsx" /* webpackChunkName: "component---src-pages-contact-uinno-tsx" */),
  "component---src-pages-development-solutions-cto-tsx": () => import("./../../../src/pages/development-solutions-cto.tsx" /* webpackChunkName: "component---src-pages-development-solutions-cto-tsx" */),
  "component---src-pages-development-solutions-ntl-tsx": () => import("./../../../src/pages/development-solutions-ntl.tsx" /* webpackChunkName: "component---src-pages-development-solutions-ntl-tsx" */),
  "component---src-pages-development-solutions-smb-tsx": () => import("./../../../src/pages/development-solutions-smb.tsx" /* webpackChunkName: "component---src-pages-development-solutions-smb-tsx" */),
  "component---src-pages-development-solutions-startup-tsx": () => import("./../../../src/pages/development-solutions-startup.tsx" /* webpackChunkName: "component---src-pages-development-solutions-startup-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-industries-elearning-development-tsx": () => import("./../../../src/pages/industries/elearning-development.tsx" /* webpackChunkName: "component---src-pages-industries-elearning-development-tsx" */),
  "component---src-pages-industries-fintech-development-tsx": () => import("./../../../src/pages/industries/fintech-development.tsx" /* webpackChunkName: "component---src-pages-industries-fintech-development-tsx" */),
  "component---src-pages-industries-hrtech-development-tsx": () => import("./../../../src/pages/industries/hrtech-development.tsx" /* webpackChunkName: "component---src-pages-industries-hrtech-development-tsx" */),
  "component---src-pages-industries-index-tsx": () => import("./../../../src/pages/industries/index.tsx" /* webpackChunkName: "component---src-pages-industries-index-tsx" */),
  "component---src-pages-industries-software-engineering-tsx": () => import("./../../../src/pages/industries/software-engineering.tsx" /* webpackChunkName: "component---src-pages-industries-software-engineering-tsx" */),
  "component---src-pages-partnership-tsx": () => import("./../../../src/pages/partnership.tsx" /* webpackChunkName: "component---src-pages-partnership-tsx" */),
  "component---src-pages-pricing-tsx": () => import("./../../../src/pages/pricing.tsx" /* webpackChunkName: "component---src-pages-pricing-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-product-development-ai-ml-development-services-tsx": () => import("./../../../src/pages/product-development/ai-ml-development-services.tsx" /* webpackChunkName: "component---src-pages-product-development-ai-ml-development-services-tsx" */),
  "component---src-pages-product-development-cto-advisory-services-tsx": () => import("./../../../src/pages/product-development/cto-advisory-services.tsx" /* webpackChunkName: "component---src-pages-product-development-cto-advisory-services-tsx" */),
  "component---src-pages-product-development-custom-development-services-tsx": () => import("./../../../src/pages/product-development/custom-development-services.tsx" /* webpackChunkName: "component---src-pages-product-development-custom-development-services-tsx" */),
  "component---src-pages-product-development-index-tsx": () => import("./../../../src/pages/product-development/index.tsx" /* webpackChunkName: "component---src-pages-product-development-index-tsx" */),
  "component---src-pages-product-development-it-staff-augmentation-services-tsx": () => import("./../../../src/pages/product-development/it-staff-augmentation-services.tsx" /* webpackChunkName: "component---src-pages-product-development-it-staff-augmentation-services-tsx" */),
  "component---src-pages-product-development-mobile-development-services-tsx": () => import("./../../../src/pages/product-development/mobile-development-services.tsx" /* webpackChunkName: "component---src-pages-product-development-mobile-development-services-tsx" */),
  "component---src-pages-product-development-mvp-development-services-tsx": () => import("./../../../src/pages/product-development/mvp-development-services.tsx" /* webpackChunkName: "component---src-pages-product-development-mvp-development-services-tsx" */),
  "component---src-pages-product-development-no-code-development-services-tsx": () => import("./../../../src/pages/product-development/no-code-development-services.tsx" /* webpackChunkName: "component---src-pages-product-development-no-code-development-services-tsx" */),
  "component---src-pages-product-development-solution-design-services-tsx": () => import("./../../../src/pages/product-development/solution-design-services.tsx" /* webpackChunkName: "component---src-pages-product-development-solution-design-services-tsx" */),
  "component---src-pages-product-development-tech-consulting-services-tsx": () => import("./../../../src/pages/product-development/tech-consulting-services.tsx" /* webpackChunkName: "component---src-pages-product-development-tech-consulting-services-tsx" */),
  "component---src-pages-product-development-web-3-blockchain-development-services-tsx": () => import("./../../../src/pages/product-development/web3-blockchain-development-services.tsx" /* webpackChunkName: "component---src-pages-product-development-web-3-blockchain-development-services-tsx" */),
  "component---src-pages-product-development-web-development-services-tsx": () => import("./../../../src/pages/product-development/web-development-services.tsx" /* webpackChunkName: "component---src-pages-product-development-web-development-services-tsx" */),
  "component---src-pages-product-discovery-services-tsx": () => import("./../../../src/pages/product-discovery-services.tsx" /* webpackChunkName: "component---src-pages-product-discovery-services-tsx" */),
  "component---src-pages-terms-and-conditions-tsx": () => import("./../../../src/pages/terms-and-conditions.tsx" /* webpackChunkName: "component---src-pages-terms-and-conditions-tsx" */),
  "component---src-pages-uinno-software-development-services-reviews-tsx": () => import("./../../../src/pages/uinno-software-development-services-reviews.tsx" /* webpackChunkName: "component---src-pages-uinno-software-development-services-reviews-tsx" */),
  "component---src-pages-uiux-design-services-tsx": () => import("./../../../src/pages/uiux-design-services.tsx" /* webpackChunkName: "component---src-pages-uiux-design-services-tsx" */)
}

