import React from 'react';
import { Script, ScriptStrategy } from 'gatsby';

export const AnalyticsGoogle = () => {
  return (
    <>
      <Script src="https://www.googletagmanager.com/gtag/js?id=G-3HEJQJ9FV5" strategy={ScriptStrategy.postHydrate} />
      <Script strategy={ScriptStrategy.postHydrate}>
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag() {
              dataLayer.push(arguments);
          }
          gtag('js', new Date());
          gtag('config', "G-3HEJQJ9FV5");
        `}
      </Script>
    </>
  );
};
