import { createSlice } from '@reduxjs/toolkit';
import { ModalsTypeKey } from 'components/Modal/ModalProps';

const initialState = {
  isOpen: false,
  modalType: null as ModalsTypeKey | null,
  payload: null,
  modalProps: {
    size: 'default',
  },
  context: null,
};

export const modalsSlice = createSlice({
  name: 'Modals',
  initialState: initialState,
  reducers: {
    showModal: (state, { payload }) => {
      state.isOpen = true;
      state.modalType = payload.type;
      state.payload = payload.payload || null;
      state.modalProps = {
        ...initialState.modalProps,
        ...(payload.size && { size: payload.size }),
      };
      state.context = payload.context || null;
    },
    hideModal: (state) => {
      state.isOpen = false;
      state.modalType = null;
      state.payload = null;
      state.context = null;
    },
  },
});
